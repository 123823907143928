import { CHECK_COMMENT_CHALLENGE } from '@client/collections/Challenge/schemas/checkCommentChallenge'
import { CREATE_USER_SHARE } from '@client/collections/Challenge/schemas/createUserShare'
import { gqlApiInstance } from '@client/init'
import { CheckCommentChallengeType } from '@models/challenge/CheckCommentChallengeType'
import { CreateUserShareParamsType } from '@models/challenge/CreateUserShareParamsType'

export function useChallengeAction() {
  async function checkCommentChallenge(
    wordCount: number
  ): Promise<CheckCommentChallengeType> {
    const res = await gqlApiInstance.request(CHECK_COMMENT_CHALLENGE, {
      wordCount,
    })
    return res.checkCommentChallenge
  }

  async function createUserShare(
    params: CreateUserShareParamsType
  ): Promise<{ id: number }> {
    const res = await gqlApiInstance.request(CREATE_USER_SHARE, {
      createUserShareInput: { ...params },
    })
    return res.createUserShare
  }

  return {
    checkCommentChallenge,
    createUserShare,
  }
}
