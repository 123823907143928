import React from 'react'
import { useMutation } from 'react-query'

import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'
import { CreateUserShareParamsType } from '@models/challenge/CreateUserShareParamsType'
import { useAuthentication } from '@hooks/useAuthentication'
import { useChallengeAction } from '@hooks/challenge/useChallengeAction'
import { ShareButtonsProps, WithShareButtonsProps } from './interface'

export function withShareButtons(Component: React.FC<ShareButtonsProps>) {
  const ShareButtons = ({ type, ...props }: WithShareButtonsProps) => {
    const { isDarkTheme } = useTheme()
    const challengeClient = useChallengeAction()
    const { isAuthenticated } = useAuthentication()

    const { mutate } = useMutation((params: CreateUserShareParamsType) =>
      challengeClient.createUserShare(params)
    )

    function createUserShare(params: CreateUserShareParamsType) {
      if (isAuthenticated && (type === 'story' || type === 'story2')) {
        mutate(params)
      }
    }

    const componentProps = { ...props, type, isDarkTheme, createUserShare }

    return <Component {...componentProps} />
  }
  return ShareButtons
}
