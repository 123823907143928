import { LineShareButtonProps } from './interface'

export function LineShareButton({
  url,
  children,
  onClick,
}: LineShareButtonProps) {
  return (
    <a
      href={`https://social-plugins.line.me/lineit/share?url=${url}`}
      onClick={() => {
        if (onClick) onClick()
      }}
      target='_blank'
      rel='noreferrer'
    >
      {children}
    </a>
  )
}
