import { useEffect, useRef, useState, useCallback, ReactNode } from 'react'
import { PortalProps, usePortal } from './usePortal'

interface PortalDropdownProps {
  initialDefaultOpen?: boolean
  placement?: 'left' | 'right'
  autoDropdownWidth?: boolean
  onScrollHidden?: boolean
}

export const useDropdown = () => {
  const dropdownRef = useRef<any>(null)
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  function toggleDropdown() {
    setIsShowDropdown(prev => !prev)
  }

  useEffect(() => {
    function pageClickEvent(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        isShowDropdown
      ) {
        setIsShowDropdown(false)
      }
    }

    document.addEventListener('click', pageClickEvent)

    return () => {
      document.removeEventListener('click', pageClickEvent)
    }
  }, [isShowDropdown])

  return {
    dropdownRef,
    isShowDropdown,
    toggleDropdown,
  }
}

export const useMutiSelectDropDown = (initialDefaultOpen = false) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isShowDropdown, setIsShowDropdown] = useState(initialDefaultOpen)

  const toggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown)
  }
  useEffect(() => {
    const pageClickEvent = (e: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsShowDropdown(false)
      }
    }
    if (isShowDropdown) {
      window.addEventListener('click', pageClickEvent)
    }
    return () => {
      window.removeEventListener('click', pageClickEvent)
    }
  }, [isShowDropdown])

  return { dropdownRef, isShowDropdown, toggleDropdown }
}

export const usePortalDropdown = ({
  initialDefaultOpen = false,
  placement = 'left',
  autoDropdownWidth = false,
  onScrollHidden = false,
}: PortalDropdownProps = {}) => {
  const { Portal, portalRef, targetRef } = usePortal()
  const [isShowDropdown, setIsShowDropdown] = useState(initialDefaultOpen)

  const toggleDropdown = (e: any) => {
    if (!targetRef.current && e.currentTarget && e.currentTarget !== document) {
      targetRef.current = e.currentTarget
    }

    setIsShowDropdown(prev => !prev)
  }

  const hideDropdown = () => {
    setIsShowDropdown(false)
  }

  const updateDropdownPosition = () => {
    const portalPosition = portalRef.current.style.position
    const { clientWidth: portalWidth, clientHeight: portalHeight } =
      portalRef.current

    const clickedEl = targetRef.current
    const { top, left, right } = clickedEl.getBoundingClientRect()
    let l = left
    let r = right - portalWidth
    let t = top + clickedEl.clientHeight
    t = portalPosition === 'absolute' ? t + window.scrollY : t

    const outRight = window.innerWidth < left + portalWidth
    const outLeft = r < 0
    const outBottom = window.innerHeight < top + portalHeight

    if (outRight) {
      l =
        window.innerWidth - (right - left + clickedEl.offsetWidth) - portalWidth
    }

    if (outLeft) {
      r = 0
    }

    if (outBottom) {
      t = window.scrollY + (top - portalHeight)
    }

    portalRef.current.style.top = `${t}px`
    if (placement === 'left') {
      portalRef.current.style.left = `${l}px`
    } else {
      portalRef.current.style.left = `${r}px`
    }
    if (autoDropdownWidth) {
      portalRef.current.style.width = `${targetRef.current.offsetWidth}px`
    }
  }
  useEffect(() => {
    function handleScroll() {
      if (
        targetRef.current &&
        portalRef.current &&
        isShowDropdown &&
        !onScrollHidden
      ) {
        updateDropdownPosition()
      } else {
        setIsShowDropdown(false)
      }
    }

    function pageClickEvent(e: any) {
      if (targetRef.current && !targetRef.current.contains(e.target)) {
        setIsShowDropdown(false)
      }
    }

    if (isShowDropdown) {
      updateDropdownPosition()
      window.addEventListener('click', pageClickEvent)
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('click', pageClickEvent)
      window.removeEventListener('resize', handleScroll)
    }
  }, [isShowDropdown])

  const Dropdown = useCallback(
    (props: PortalProps) => {
      return <Portal {...props} />
    },
    [Portal]
  )

  return {
    Dropdown,
    isShowDropdown,
    toggleDropdown,
    hideDropdown,
  }
}
