import { useEffect } from 'react'
import cn from 'classnames'

import { ShareIcon } from '@components/icons/ShareIcon'
import { usePortalDropdown } from '@hooks/useDropdown'
import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'
import { ShareButtons } from '@features/story/pages/StoryPage/components/ShareButtons'
import { shareButtonStyle } from '@features/story/pages/StoryPage/components/ShareButtons/ShareButtons'
import { SocialSharingProps } from './inteface'

export function SocialSharing({
  bookId,
  bookChapterId,
  buttonText,
  buttonClassName,
  shareUrl,
  type = 'character',
}: SocialSharingProps) {
  const { isDarkTheme, isLightTheme, isNightTheme, isSepiaTheme } = useTheme()
  const { Dropdown, toggleDropdown, isShowDropdown, hideDropdown } =
    usePortalDropdown({
      placement: 'right',
      onScrollHidden: true,
    })

  useEffect(() => {
    function hide() {
      hideDropdown()
    }

    const id = document.getElementById('drawer-id')
    if (id) {
      id.addEventListener('scroll', hide)
    }

    return () => {
      window.removeEventListener('scroll', hide)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <button
        className={cn('flex items-center justify-center', buttonClassName, {
          'min-w-[30px] h-[30px] rounded-lg text-secondary-1 border border-gray-4':
            type === 'character',
          'w-10 h-10 min-w-10 min-h-10 rounded-full text-secondary-1 border border-gray-4':
            type === 'story',
          'h-10 min-w-10 min-h-10 rounded-[20px] tablet-mobile:rounded-full px-[20px] tablet-mobile:px-0 tablet-mobile:w-[30px] tablet-mobile:h-[30px]':
            type === 'chapter',
          'bg-gray-4 text-secondary':
            (isLightTheme || isNightTheme) && type === 'chapter',
          'bg-white text-secondary': isSepiaTheme && type === 'chapter',
          'bg-dark-3 text-gray-16': isDarkTheme && type === 'chapter',
        })}
        type='button'
        onClick={toggleDropdown}
      >
        <ShareIcon
          width={shareButtonStyle[type]?.shareIconWith}
          height={shareButtonStyle[type]?.shareIconHeight}
        />
        {buttonText && (
          <span className='ml-2 font-mitr font-medium tablet-mobile:hidden'>
            {buttonText}
          </span>
        )}
      </button>
      <Dropdown zIndex={100}>
        <ShareButtons
          type={type}
          bookId={Number(bookId)}
          bookChapterId={bookChapterId}
          className={cn({ hidden: !isShowDropdown })}
          shareUrl={shareUrl}
        />
      </Dropdown>
    </div>
  )
}
