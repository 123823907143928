import { gql } from 'graphql-request'

export const CHECK_COMMENT_CHALLENGE = gql`
  query PromotionChallenge($wordCount: Int!) {
    checkCommentChallenge(wordCount: $wordCount) {
      promotionChallenge {
        wordCount
        benefitValue
        benefitCoin {
          name
        }
      }
      remainingCount
      isHasChallenge
    }
  }
`
