import Script from 'next/script'

import { FacebookIcon } from '@components/icons/FacebookIcon'
import { FACEBOOK_ID } from '@configs/config'
import { FacebookButtonProps } from './interface'

export function FacebookButton({
  url,
  className,
  width,
  height,
  onSuccess,
  onError,
}: FacebookButtonProps) {
  return (
    <div className={className}>
      <button
        type='button'
        onClick={() => {
          window.FB.ui(
            {
              method: 'share',
              href: url,
            },
            function (response: any) {
              if (response) {
                onSuccess()
              } else {
                onError()
              }
            }
          )
        }}
      >
        <FacebookIcon width={width} height={height} />
      </button>
    </div>
  )
}
