import React from 'react'

import { useAlert } from '@hooks/useAlert'
import { LinkShareButtonProps, LinkShareButtonAcceptProps } from './interface'

export function withLinkShareButton(Component: React.FC<LinkShareButtonProps>) {
  function Hoc({ ...props }: LinkShareButtonAcceptProps) {
    const alert = useAlert()

    function handleCopyLink(url: string) {
      navigator.clipboard.writeText(url).then(() => {
        alert.success('คัดลอกลิงก์เรียบร้อยแล้ว')
      })
    }

    const componentProps = { ...props, handleCopyLink }

    return <Component {...componentProps} />
  }

  return Hoc
}
