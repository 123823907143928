import React from 'react'
import { SocialSharingProps } from './inteface'

export function withSocialSharing(Component: React.FC<SocialSharingProps>) {
  function WithSocialSharing(props: SocialSharingProps) {
    const componentProps = {
      ...props,
    }
    return <Component {...componentProps} />
  }

  return WithSocialSharing
}
